import React from "react";
import { graphql, navigate, PageProps, Script } from "gatsby";

import { Query } from "@graphql-types";
import { Blocks, ErrorMsg, Header, HeroSlider, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import { InfoPacksLandingPageContext } from "@state/types";
import AllInfoPacksInRegions from "@components/forms/allInfoPacksInRegions";
import Popup from "@components/forms/popup";
import { Helmet } from "react-helmet";
import { DropdownContainer } from "@styles/formStyles";
import styled from "styled-components";
import { isBrowser } from "@util/helper";
import { useStore } from "@state/store";

type PageContext = PageProps & InfoPacksLandingPageContext;
interface Props {
  data: Query;
  pageContext: PageContext;
}

export default function InfoPacksLandingTemplate({
  data: { sanityInfoPacksLanding, sanityHeader },
  pageContext: { infoPacksInRegions },
}: Props) {
  if (sanityInfoPacksLanding == null)
    return <ErrorMsg data={sanityInfoPacksLanding} msg="Error fetching data for page" />;

  const { setRegionAccordionOpen } = useStore();

  const {
    slug,
    _type,
    pageColour,
    seo,
    blocks,
    descriptionText,
    title,
    generalBrochureImage,
    generalBrochureText,
    popupText,
    heroSlider,
  } = sanityInfoPacksLanding;
  usePageMeta(pageColour?.colour?.hex, _type);

  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);

    // navigate(`/village-info-packs/#${e.target.value}`);
    if (isBrowser()) {
      setRegionAccordionOpen(e.target.value);
      const element = document.getElementById(e.target.value);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const RegionDropdown = () => {
    const sortedRegions = infoPacksInRegions
      .filter(region => {
        const allForms = region.forms.filter(form => form.disabledInfoPack != true);
        if (allForms.length > 0) return true;
      })
      .map(region => region.region)
      .sort((a, b) => {
        if (a == null || b == null) return 0;
        if (a.marketoValue < b.marketoValue) return -1; // a comes before b
        if (a.marketoValue > b.marketoValue) return 1; // b comes before a
        return 0; // a and b are the same
      });

    return (
      <StyledDropdownWrapper>
        <DropdownContainer
          id="regionSelect"
          aria-required="true"
          required
          onChange={handleRegionChange}
          noLabel
        >
          <option value="" selected>
            Select location*
          </option>
          {sortedRegions.map(region => {
            if (region == null || region.title == null) return null;
            return <option value={region.title}>{region.marketoValue}</option>;
          })}
          <option value="general-brochure">General Brochure</option>
        </DropdownContainer>
      </StyledDropdownWrapper>
    );
  };

  return (
    <StyledInfoLandingWrapper>
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `function d365mktConfigureTracking() {
     return {Anonymize: true};
}`,
          }}
        ></script>
      </Helmet>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      {heroSlider && <HeroSlider data={heroSlider} childContent={<RegionDropdown />} />}
      <AllInfoPacksInRegions
        data={infoPacksInRegions}
        title={title}
        descriptionText={descriptionText}
        generalBrochure={{ generalBrochureImage, generalBrochureText }}
      />
      {blocks && <Blocks data={blocks} />}
      <Popup data={popupText} type="info pack" />
    </StyledInfoLandingWrapper>
  );
}

export const query = graphql`
  query infoPacksLandingQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityInfoPacksLanding {
      _type
      title
      heroSlider {
        ...sanityHeroSlider
      }
      slug {
        current
      }
      seo {
        ...sanitySeo
      }
      descriptionText {
        _rawBlockContent
      }
      pageColour {
        ...sanityColorPicker
      }
      generalBrochureImage {
        ...sanityFullWidthImage
      }
      generalBrochureText {
        ...sanityBlockContent
      }
      popupText {
        ...sanityBlockContent
      }
      blocks {
        ...staticPageBlocks
      }
    }
  }
`;

const StyledDropdownWrapper = styled.div`
  margin: 55px 0 0 0;
  max-width: 280px;
`;

const StyledInfoLandingWrapper = styled.div`
  .hero-content-wrapper {
    opacity: 1;
  }
`;
