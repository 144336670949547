import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Maybe, SanityBlockContent } from "@graphql-types";
import { ModalBox, BlocksContent, Button } from "@global";
import { AbsoluteWrapper, Container } from "@util/standard";
import { useStore } from "@state/store";
import { assets, mediaQuery } from "@util/constants";

interface Props {
  data: Maybe<SanityBlockContent> | undefined;
  type: "guide" | "info pack";
}

export default function Popup({ data, type }: Props) {
  const { drawerVisible, setDrawerVisible } = useStore();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (drawerVisible === type) setVisible(true);
  }, [drawerVisible]);

  const handleClose = () => setVisible(false);

  const handleDownloadClick = () => {
    if (visible) setVisible(false);
    if (type === "guide") setDrawerVisible("guides-form");
    if (type === "info pack") setDrawerVisible("info-packs-form");
  };

  if (data == null) return null;
  return (
    <ModalBox hideClose height="unset" isOpen={visible} setIsOpen={setVisible}>
      <PopupInner
        flexDirection="column"
        backgroundColor="white"
        width="40%"
        margin="auto"
        padding="50px"
        position="relative"
      >
        <BlocksContent data={data} />
        <Container isMobileColumn columnGap="15px" rowGap="15px">
          <Button onClick={handleDownloadClick}>{`Download ${type}`}</Button>
          <Button onClick={handleClose}>Continue browsing</Button>
        </Container>
        <AbsoluteWrapper top="30px" right="30px">
          <Container onClick={handleClose} cursor="pointer" alignItems="center" columnGap="8px">
            <img height="12px" width="12px" src={assets.close} />
            <span>Close</span>
          </Container>
        </AbsoluteWrapper>
      </PopupInner>
    </ModalBox>
  );
}

const PopupInner = styled(Container)`
  h3 {
    font-size: 32px;
  }
  ${mediaQuery.smallLaptopDown} {
    width: 60%;
  }
  ${mediaQuery.tabletDown} {
    width: 80%;
  }
  ${mediaQuery.mobileDown} {
    padding: 30px;
  }
`;
